<template>
  <div class="">
    <slot />
  </div>
</template>

<style>
body {
  @apply bg-background;
}
</style>
